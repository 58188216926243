import * as React from 'react'
import {
  withPreviewResolver,
  WithPreviewResolverProps,
} from 'gatsby-source-prismic'
import styled from '@emotion/styled'
import { PageProps } from 'gatsby'
import { LinkResolver } from 'gatsby-source-prismic/dist/types'

import Layout from '../components/Layout'

const PreviewPage: React.FC<PageProps & WithPreviewResolverProps> = ({
  isPreview,
  isLoading,
}) => {
  return (
    <Layout>
      <PreviewContent>Loading...</PreviewContent>
    </Layout>
  )
}

const PreviewContent = styled('div')({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const linkResolver: () => LinkResolver = () => (doc) => {
  const type = (doc as { type: string }).type
  if (type === 'warranty_returns') {
    return `/warranty-returns`
  } else if (type === 'privacy_policy') {
    return `/privacy-policy`
  } else if (type === 'terms_of_service') {
    return `/terms`
  }
  return '/'
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'oda-content',
  linkResolver,
})
